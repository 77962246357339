<template>
  <div class="add-new-role" v-if="role.permissions">
    <title-bar
        submitBtn
        :submitBtnTitle="$t('messages.roleCreate')"
        :title="$t('messages.roleCreate')"
        @submitPressed="submitPressed"
    />
    <role-form
        :roleObject="role"
        :action="action"
        @clearAction="action = null"
    />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import RoleForm from "./components/RoleForm.vue";
import ListUtils from "@/mixins/ListUtils";

export default {
  mixins: [ListUtils],
  components: {
    TitleBar,
    RoleForm,
  },
  data() {
    return {
      action: null,
      permissionsNames: [],
      role: {
        name: "Roles",
        permissions: [],
        additionalPermissions: [],
      },
    };
  },
  created() {
    this.$Enums.getCollection({params: {pagination: false, cache: true, ttl: 86400}})
        .then(response => {
          response.data['hydra:member'].forEach(element => {
            if (element.name === 'USER_ACCESS_PERMISSION') {
              element.keys.forEach(key => {
                this.role.permissions.push({
                  name: key,
                  LIST: false,
                  LIST_ALL: false,
                  VIEW: false,
                  CREATE: false,
                  UPDATE: false,
                  DELETE: false,
                })
              })
            }
            if (element.name === 'ADDITIONAL_USER_ACCESS_PERMISSION') {
              element.keys.forEach(key => {
                this.role.additionalPermissions.push({
                  name: key,
                  value: false,
                })
              })
            }
          })
        })
  },
  methods: {
    submitPressed() {
      this.action = "add";
    },
  },
};
</script>
